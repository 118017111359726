<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Personal Information</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Update your personal informaiton</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <button type="button" class="btn btn-secondary" @click="cancel()">Cancel</button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <v-snackbar v-model="snackbar" absolute top right color="success">
      <span>Profile Info Updated</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Customer Info</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Avatar</label>
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div
                class="image-input-wrapper"
                :style="{ backgroundImage: `url(${photo})` }"
              ></div>
              <label
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input
                  type="file"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                  @change="onFileChange($event)"
                />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel"
                data-toggle="tooltip"
                title="Cancel avatar"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="remove"
                data-toggle="tooltip"
                title="Remove avatar"
                @click="current_photo = null"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
            <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">First Name</label>
          <div class="col-lg-9 col-xl-6">
            <input
              ref="name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="currentUserPersonalInfo.name"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Address</label>
          <div class="col-lg-9 col-xl-6">
            <input
              ref="address"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="currentUserPersonalInfo.address"
            />
          </div>
        </div>
        <!-- <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Last Name</label>
          <div class="col-lg-9 col-xl-6">
            <input
              ref="surname"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="currentUserPersonalInfo.surname"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Company Name</label>
          <div class="col-lg-9 col-xl-6">
            <input
              ref="company_name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="currentUserPersonalInfo.company_name"
            />
            <span class="form-text text-muted"
              >If you want your invoices addressed to a company. Leave blank to use your
              full name.</span
            >
          </div>
        </div> -->
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Contact Info</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Contact Phone</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input
                ref="phone"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Phone"
                v-bind:value="currentUserPersonalInfo.phone"
              />
            </div>
            <span class="form-text text-muted"
              >We'll never share your personal info with anyone.</span
            >
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Email Address</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                ref="email"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Email"
                v-bind:value="currentUserPersonalInfo.email"
                disabled
              />
            </div>
          </div>
        </div>
        <!-- <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Company Site</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input
                ref="company_site"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Username"
                v-bind:value="currentUserPersonalInfo.company_site"
              />
              <div class="input-group-append">
                <span class="input-group-text">.com</span>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";

export default {
  name: "PersonalInformation",
  data() {
    return {
      default_photo: "media/users/blank.png",
      current_photo: null,
      snackbar: false,
    };
  },
  mounted() {
    this.current_photo = this.currentUserPersonalInfo.photo;
  },
  methods: {
    save() {
      var id = this.currentUserPersonalInfo.id;
      var name = this.$refs.name.value;
      var phone = this.$refs.phone.value;
      var address = this.$refs.address.value;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay

      // send update request
      this.$store
        .dispatch(UPDATE_PERSONAL_INFO, {
          id: id,
          name: name,
          phone: phone,
          address: address,
        })
        .then((res) => {
          this.snackbar = true;
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        });
    },
    cancel() {
      this.$router.push({ name: "student-dashboard" });
      // this.$refs.name.value = this.currentUserPersonalInfo.name;
      // this.$refs.surname.value = this.currentUserPersonalInfo.surname;
      // this.$refs.company_name.value = this.currentUserPersonalInfo.company_name;
      // this.$refs.phone.value = this.currentUserPersonalInfo.phone;
      // this.$refs.email.value = this.currentUserPersonalInfo.email;
      // this.$refs.company_site.value = this.currentUserPersonalInfo.company_site;
      // this.current_photo = this.currentUserPersonalInfo.photo;
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    photo() {
      return this.current_photo == null ? this.default_photo : this.current_photo;
    },
  },
};
</script>
